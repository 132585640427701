<template>
  <v-container id="regular-tables" fluid tag="section">
    <base-material-card
      v-if="object_type != 'is_leisure'"
      icon="mdi-clipboard-text"
      title="Состояние броней"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :loading="loading"
        loading-text="Идёт загрузка данных..."
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="3" sm="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Дата"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  :first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Отмена
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      getData();
                      $refs.menu.save(date);
                    "
                  >
                    Сохранить
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-select
                v-model="filter.booking_status"
                label="Статус бронирования"
                :items="statuses"
                @change="getData"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.check_in`]="{ item }">
          {{ formatDate(item.check_in) }}
        </template>
        <template v-slot:[`item.check_out`]="{ item }">
          {{ formatDate(item.check_out) }}
        </template>
        <template v-slot:[`item.canceled_at`]="{ item }">
          {{ formatDate(item.canceled_at) }}
        </template>
        <template v-slot:[`item.full_name`]="{ item }">
          {{ item.full_name || "-" }}
        </template>
        <template v-slot:[`item.phone_number`]="{ item }">
          {{ item.phone_number || "-" }}
        </template>
      </v-data-table>
    </base-material-card>
    <div class="py-3" />
  </v-container>
</template>

<script>
import moment from "moment";
import "moment-timezone";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";

export default {
  data() {
    return {
      object_type: localStorage.getItem("object_type"),
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      filter: {
        booking_status: "all"
      },
      totalItems: 0,
      options: {},
      firstWatcherTrigger: false,
      headers: [
        {
          text: "Время Заезда",
          value: "check_in",
          class: "table-title",
          sortable: false
        },
        {
          text: "Время Выезда",
          value: "check_out",
          class: "table-title",
          sortable: false
        },
        {
          text: "Категория",
          value: "category_name",
          class: "table-title",
          sortable: false
        },
        {
          text: "Номер",
          value: "room_name",
          class: "table-title",
          sortable: false
        },
        {
          text: "ФИО",
          value: "full_name",
          class: "table-title",
          sortable: false
        },
        {
          text: "Номер телефона",
          value: "phone_number",
          class: "table-title",
          sortable: false
        },
        {
          text: "Количество людей",
          value: "place",
          class: "table-title",
          sortable: false
        },
        {
          text: "Стоимость",
          value: "price",
          class: "table-title",
          sortable: false
        },
        {
          text: "Оплачено",
          value: "paid",
          class: "table-title",
          sortable: false
        },
        {
          text: "К оплате",
          value: "duty",
          class: "table-title",
          sortable: false
        },
        {
          text: "Причина отмены",
          value: "cancel_reason",
          class: "table-title",
          sortable: false
        },
        {
          text: "Комментарий",
          value: "comment",
          class: "table-title",
          sortable: false
        }
      ],
      items: [],
      statuses: [
        { text: "Все", value: "all" },
        { text: "Заезды", value: "check_in" },
        { text: "Выезды", value: "check_out" },
        { text: "Проживание", value: "accommodation" },
        { text: "Отмененные", value: "canceled" }
      ],
      loading: false
    };
  },
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  created() {
    if (this.object_type != "is_leisure") this.loadRow();
  },
  computed: {
    dateFormatted() {
      return moment(this.date).format("DD.MM.YYYY");
    }
  },
  methods: {
    formatDate(date) {
      return moment(date * 1000).format("DD.MM.YYYY HH:mm");
    },
    getData() {
      this.options.page = 1;
      this.loadRow();
    },
    async loadRow() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        ...this.filter,
        start: new Date(this.date).getTime() / 1000
      };
      try {
        let res = await BookingApiUrls.getBookings(params);
        this.totalItems = res.meta.pagination.count;
        res.results.forEach(e => {
          e.place = `
            Взрослый(${e.adult_count + e.main_adult_count})
            Детский(${e.child_count + e.main_child_count})
            Дети без места(${e.child_without_place})
          `;
        });
        this.items = res.results;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 480) {
  //уменьшить в адаптивке иконку
  #regular-tables ::v-deep .pa-7 {
    padding: 5vw !important;
  }
}
</style>
